// penalty namespace

@mixin make_border_part($prefix, $position, $suffix, $size, $color, $style) {
  .#{$prefix}-#{$position}-#{$style}-#{$suffix} {
    border-#{$position}: $size $style $color;
  }
}

.p7 {
  font-family: Roboto-Font, sans-serif;

  .cursor-pointer {
    cursor: pointer;
  }

  .t-underline {
    text-decoration: underline;
  }

  .t-blue {
    color: $p7-color_blue;
  }

  .t-red {
    color: $p7-color_red;
  }

  .t-yellow {
    color: $p7-color_yellow;
  }

  .t-green {
    color: $p7-color_green;
  }

  &.sc {
    background: $p7-color_green3;
  }

  .game {
    position: relative;

    .a7-main {
      .a7 {
        position: absolute;
        z-index: 200;
        background: white;
        border-radius: 0.2rem;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

        @media (orientation: portrait) {
          width: 95%;
        }

        @media (orientation: landscape) {
          width: 80%;
        }

        .pic {
          height: 50vh;
        }
      }

      .cmd {
        background: grey;
        color: white;
      }
    }

    .header-sky {
      position: relative;
      z-index: 0;

      @media (orientation: portrait) {
        height: $p7-header-sky-height_port;
        font-size: $p7-font-info_s2-port;

        .logo {
          height: $p7-logo-size_port;
          width: $p7-logo-size_port;
        }

        @media (max-aspect-ratio: 470/1000) {
          height: $p7-header-sky-heightx_port;
        }
      }
      @media (orientation: landscape) {
        height: $p7-header-sky-height_land;
        font-size: $p7-font-info_s2-land;

        .logo {
          height: $p7-logo-size_land;
          width: $p7-logo-size_land;
        }
      }

      background: linear-gradient(to bottom, $p7-color_sky 0%, darken($p7-color_sky, 7%) 100%);
      color: white;

      .title {
        text-align: center;
      }

      .menu {
        position: absolute;
        left: 0;
        top: 0;
      }

      .search {
        position: absolute;
        right: 0;
        top: 0;
      }

      .rules {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .logo {
        //        background: #5CB03C;
        position: relative;

        .pb-logo {
          vertical-align: baseline;
          height: 100%;
          width: 100%;
        }

        .date, .time {
          position: absolute;
          white-space: nowrap;
          top: 50%;
        }

        .date {
          left: -0.5vh;
          transform: translate(-100%, -50%);
          text-align: right;
        }

        .time {
          right: -0.5vh;
          transform: translate(100%, -50%);
          text-align: left;
        }
      }

      .active {
        color: white;
        text-decoration: underline;
      }
    }

    .tribune {
      position: relative;
      z-index: -5;

      @media (orientation: portrait) {
        height: $p7-tribune-height_port;
      }
      @media (orientation: landscape) {
        height: $p7-tribune-height_land;
      }

      background-image: url(/img/ff_tribune.png);

      background-size: auto 100%;
      background-repeat: repeat-x;
      background-position: center;
    }

    .info-board {
      position: absolute;

      z-index: 20;

      left: 50%;
      transform: translate(-50%, -100%);

      color: white;
      background: $p7-color_info_main;
      border: transparent 0 solid;
      border-radius: 1.5vh;

      padding-top: 0.5vh;
      padding-bottom: 0.5vh;

      .central {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);

        height: 100%;
      }

      @media (orientation: portrait) {
        top: $p7-info_main-top_port;

        .left {
          margin-left: $p7-info_main-panel_margin_short_port;
          margin-right: $p7-info_main-panel_margin_wide_port;
        }

        .right {
          margin-left: $p7-info_main-panel_margin_wide_port;
          margin-right: $p7-info_main-panel_margin_short_port;
        }

        .score {
          font-size: $p7-font-info_s0-port;
          top: $p7-info_main-score_top_port;

          .left {
            right: $p7-info_main-score_padding_port;
          }

          .right {
            left: $p7-info_main-score_padding_port;
          }
        }

        .info-score {
          font-size: $p7-font-info_s3-port;
          margin-bottom: $p7-info_score_margin_bottom_port;
        }

        .nickname {
          font-size: $p7-font-info_s2-port;
          width: $p7-info_main-panel_width_port;
        }

        .time {
          font-size: $p7-font-info_s3-port;
        }

        @media (max-aspect-ratio: 470/1000) {
          height: $p7-info_main-height_port * .8;

          .score {
            font-size: $p7-info_main-height_port * .5;
          }
          .nickname {
            font-size: $p7-info_main-height_port * .2;
          }

          .time {
            font-size: $p7-info_main-height_port * .15;
          }
        }
      }
      @media (orientation: landscape) {
        top: $p7-info_main-top_land;

        .left {
          margin-left: $p7-info_main-panel_margin_short_land;
          margin-right: $p7-info_main-panel_margin_wide_land;
        }

        .right {
          margin-left: $p7-info_main-panel_margin_wide_land;
          margin-right: $p7-info_main-panel_margin_short_land;
        }

        .score {
          font-size: $p7-font-info_s0-land;
          top: $p7-info_main-score_top_land;

          .left {
            right: $p7-info_main-score_padding_land;
          }

          .right {
            left: $p7-info_main-score_padding_land;
          }
        }

        .info-score {
          font-size: $p7-font-info_s3-land;
          margin-bottom: $p7-info_score_margin_bottom_land;
        }

        .nickname {
          font-size: $p7-font-info_s2-land;
          width: $p7-info_main-panel_width_land;
        }

        .time {
          font-size: $p7-font-info_s3-land;
        }
      }

      .nickname {
        color: #FFDF00;
      }

      .time {
        font-style: italic;
        color: white;
      }

      .score {
        position: absolute;

        left: 50%;
        transform: translate(-50%, 0);

        .left, .right {
          position: absolute;
          top: 0;
          margin: 0;
        }
      }

      .time-line {
        background: #690000;
        height: 5px;
        width: 100%;

        &-a {
          height: 100%;
          background: #FF3600;
        }
      }
    }

    .animated {
      pointer-events: none;
      position: absolute;

      &-ball {
        z-index: 100;
        @media (orientation: portrait) {
          height: 1.6vh;
        }
        @media (orientation: landscape) {
          height: 3.1vh;
        }

        img {
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-keeper {
        z-index: 99;
        @media (orientation: portrait) {
          height: 12vh;
        }
        @media (orientation: landscape) {
          height: 23vh;
        }

        img {
          height: 100%;
        }
      }
    }

    .gate_ball {
      z-index: 80;
      position: absolute;

      height: 2.2vh;

      .ball {
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.z3A {
        top: 15%;
        left: 10%;
      }

      &.z3B {
        top: 15%;
        left: 60%;
      }

      &.z3C {
        top: 15%;
        left: 90%;
      }

      &.z4A {
        top: 15%;
        left: 10%;
      }

      &.z4B {
        top: 75%;
        left: 10%;
      }

      &.z4C {
        top: 75%;
        left: 90%;
      }

      &.z4D {
        top: 15%;
        left: 90%;
      }

      &.z2A {
        top: 15%;
        left: 10%;
      }

      &.z2B {
        top: 15%;
        left: 90%;
      }

      &.z5A {
        top: 15%;
        left: 10%;
      }

      &.z5B {
        top: 70%;
        left: 10%;
      }

      &.z5C {
        top: 10%;
        left: 60%;
      }

      &.z5E {
        top: 15%;
        left: 90%;
      }

      &.z5D {
        top: 70%;
        left: 90%;
      }
    }

    .gate-zones {
      position: relative;
      //            background: transparentize(blue, 0.4) ;

      left: 50%;
      transform: translate(-50%, 0);

      @media (orientation: portrait) {
        top: $p7-gate-zones-top_port;
        height: $p7-gate-zones-height_port;
        width: $p7-gate-zones-height_port * $p7-gate-aspect_port;

        .goal_keeper {
          bottom: $p7-gate-zones-forward_port;
        }

        @media (max-aspect-ratio: 470/1000) {
          height: $p7-gate-zones-heightx_port;
          width: $p7-gate-zones-heightx_port * $p7-gate-aspect_port;
        }
      }

      @media (orientation: landscape) {
        top: $p7-gate-zones-top_land;
        height: $p7-gate-zones-height_land;
        width: $p7-gate-zones-height_land * $p7-gate-aspect_land;
        .goal_keeper {
          bottom: $p7-gate-zones-forward_land;
        }
      }

      .zone- {
        &2, &3, &4, &5 {
          cursor: pointer;

          position: absolute;
          color: #FFDF00;
          font-weight: bold;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: transparentize(#FFDF00, 0.55);
            color: #FF3600;
          }

          .active {
            background: transparent;
            border-radius: 50%;
          }
        }

        &2 {
          &.A, &.B {
            @media (orientation: portrait) {
              font-size: $p7-font-info_s1-port;
              .active {
                width: $p7-gate-height_port * 0.27;
                height: $p7-gate-height_port * 0.27;
              }
            }
            @media (orientation: landscape) {
              font-size: $p7-font-info_s1-land;
              .active {
                width: $p7-font-info_s1-land * 1.8;
                height: $p7-font-info_s1-land * 1.8;
              }
            }

            width: 50%;
            height: 100%;

            bottom: 0;
          }

          &.A {
            right: 50%;
            border-right: #FFDF00 dotted 1px;
          }

          &.B {
            left: 50%;
          }
        }

        &3 {
          &.A, &.B, &.C {
            @media (orientation: portrait) {
              font-size: $p7-font-info_s1-port;
              .active {
                width: $p7-gate-height_port * 0.27;
                height: $p7-gate-height_port * 0.27;
              }
            }
            @media (orientation: landscape) {
              font-size: $p7-font-info_s1-land;
              .active {
                width: $p7-font-info_s1-land * 1.8;
                height: $p7-font-info_s1-land * 1.8;
              }
            }

            width: percentage(1/3);
            height: 100%;

            bottom: 0;
          }

          &.A {
            right: percentage(2/3);
            border-right: #FFDF00 dotted 1px;
          }

          &.B {
            left: percentage(1/3);

            .letter {
              @media (orientation: portrait) {
                margin-left: -5.6vh;
                margin-top: -5vh;
              }
              @media (orientation: landscape) {
                margin-left: -10.4vh;
                margin-top: -10.6vh;
              }
            }
          }

          &.C {
            left: percentage(2/3);
            border-left: #FFDF00 dotted 1px;
          }
        }

        &4 {
          &.A, &.B, &.C, &.D {
            @media (orientation: portrait) {
              font-size: $p7-font-info_s1-port;
              .active {
                width: $p7-gate-height_port * 0.27;
                height: $p7-gate-height_port * 0.27;
              }
            }
            @media (orientation: landscape) {
              font-size: $p7-font-info_s1-land;
              .active {
                width: $p7-font-info_s1-land * 1.8;
                height: $p7-font-info_s1-land * 1.8;
              }
            }

            width: 50%;
            height: 50%;
          }

          &.A, &.D {
            bottom: 50%;
            border-bottom: #FFDF00 dotted 1px;
          }

          &.B, &.C {
            top: 50%;
          }

          &.A, &.B {
            right: 50%;
            border-right: #FFDF00 dotted 1px;
          }

          &.C, &.D {
            left: 50%;
          }
        }

        &5 {
          &.A, &.B, &.C, &.D, &.E {
            @media (orientation: portrait) {
              font-size: $p7-font-info_s1-port;
              .active {
                width: $p7-gate-height_port * 0.27;
                height: $p7-gate-height_port * 0.27;
              }
            }
            @media (orientation: landscape) {
              font-size: $p7-font-info_s1-land;
              .active {
                width: $p7-font-info_s1-land * 1.8;
                height: $p7-font-info_s1-land * 1.8;
              }
            }

            width: percentage(1/3);
            height: 50%;
          }

          &.C {
            left: percentage(1/3);
            height: 100%;

            .letter {
              @media (orientation: portrait) {
                margin-left: -5.4vh;
                margin-top: -5.6vh;
              }
              @media (orientation: landscape) {
                margin-left: -10.4vh;
                margin-top: -10.6vh;
              }
            }
          }

          &.A, &.E {
            bottom: 50%;
            border-bottom: #FFDF00 dotted 1px;
          }

          &.B, &.D {
            top: 50%;
          }

          &.A, &.B {
            right: percentage(2/3);
            border-right: #FFDF00 dotted 1px;
          }

          &.D, &.E {
            left: percentage(2/3);
            border-left: #FFDF00 dotted 1px;
          }
        }
      }

      .goal_keeper {
        pointer-events: none;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        @media (orientation: portrait) {
          height: 12vh;
        }

        @media (orientation: landscape) {
          height: 23vh;
        }

        z-index: 20;
      }

      .gk {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    .player-step-status {
      position: absolute;
      z-index: 10;
      top: 0;

      text-shadow: none;
      color: white;
      text-transform: uppercase;
      font-style: italic;
      font-weight: bold;

      &.left {
        padding-left: 1vh;
        text-align: left;
        right: 0;
        transform: translate(100%, 0);
      }

      &.right {
        padding-right: 1vh;
        text-align: right;
        left: 0;
        transform: translate(-100%, 0);
      }

      @media (orientation: portrait) {
        width: 35vh;
        font-size: $p7_button_one-height_land * 0.8;

        @media (max-aspect-ratio: 470/1000) {
          font-size: $p7_button_one-height_land * 0.6;
        }
      }
      @media (orientation: landscape) {
        width: 50vh;
        font-size: $p7_button_one-height_land * 0.9;
      }
    }

    .info-group {
      color: white;
      text-transform: uppercase;
      font-style: italic;
      font-weight: bold;

      text-align: center;

      @media (orientation: portrait) {
        font-size: $p7_button_one-height_land * 0.6;

        @media (max-aspect-ratio: 470/1000) {
          //          color: red;
          font-size: $p7_button_one-height_land * 0.4;
        }
      }
      @media (orientation: landscape) {
        font-size: $p7_button_one-height_land * 0.7;
      }
    }

    .info-gate {
      position: absolute;
      z-index: 10;

      left: 50%;

      @media (orientation: portrait) {
        width: $p7-info_main-width_port;
        top: $p7-gate-height_port + $p7-info_gate-top_port;
        transform: translate(-50%, 0);
        font-size: $p7_button_one-height_land * 0.5;

        @media (max-aspect-ratio: 470/1000) {
          //          color: red;
          font-size: $p7_button_one-height_land * 0.4;
        }
      }
      @media (orientation: landscape) {
        width: $p7-info_main-width_land;
        top: $p7-gate-height_land + $p7-info_gate-top_land;
        transform: translate(-50%, 0);
        font-size: $p7_button_one-height_land * 0.7;
      }

      color: white;
      text-transform: uppercase;
      font-style: italic;
      font-weight: bold;

      text-align: center;
    }

    .field {
      position: relative;
      z-index: 10;

      @media (orientation: portrait) {
        background-image: url(/img/ff_port.png);

        margin-top: -$p7-gate-height_port;
        height: calc(100vh - #{$p7-header-sky-height_port} - #{$p7-tribune-height_port} + #{$p7-gate-height_port} - #{$p7-footer-height});

        .info-bottom {
          font-size: $p7-font-info_s2-port;
        }
        @media (max-aspect-ratio: 470/1000) {
          height: calc(100vh - #{$p7-header-sky-heightx_port} - #{$p7-tribune-height_port} + #{$p7-gate-height_port} - #{$p7-footer-height});
          .info-bottom {
            font-size: $p7-font-info_s2x-port;
          }
        }
      }
      @media (orientation: landscape) {
        background-image: url(/img/ff_land.png);

        margin-top: -$p7-gate-height_land;
        height: calc(100vh - #{$p7-header-sky-height_land} - #{$p7-tribune-height_land} + #{$p7-gate-height_land} - #{$p7-footer-height});

        .info-bottom {
          font-size: $p7-font-info_s2-land;
        }
      }

      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      //      overflow: hidden;

      .control {
        position: absolute;
        bottom: 0;

        pointer-events: none;

        .score {
          &.left {
            //            left: 0;
          }

          &.right {
            //          right: 0;
          }

          @media (orientation: portrait) {
            margin-bottom: $p7-control-score-bottom_port;
          }
          @media (orientation: landscape) {
            margin-bottom: $p7-control-score-bottom_land;
          }

          .gate-type-select {
            z-index: 100;
            position: absolute;
            pointer-events: all;

            top: 50%;

            &.left {
              right: 0;
              transform: translate(100%, -50%);
            }

            &.right {
              left: 0;
              transform: translate(-100%, -50%);
            }

            .info {
              position: absolute;
              top: 0;
              background: lighten($p7-color-yellow, 30%);
              border-radius: 1vh;
              color: $p7-color_blue;
              text-shadow: 1px 1px 2px #FFF;

              @media (orientation: portrait) {
                width: 35vh;
                font-size: $p7_button_one-height_land * 0.6;

                @media (max-aspect-ratio: 470/1000) {
                  font-size: $p7_button_one-height_land * 0.4;
                }
              }
              @media (orientation: landscape) {
                width: 50vh;
                font-size: $p7_button_one-height_land * 0.7;
              }

              &.left {
                left: 0;
                transform: translate(0, -100%);
              }

              &.right {
                right: 0;
                transform: translate(0, -100%);
              }
            }

            .cell {
              position: relative;

              background: transparent;
              border: none;

              margin: 0.38vh;

              .micro {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;

                @media (orientation: portrait) {
                  width: $p7-cell-size_port;
                  height: $p7-cell-size_port;
                  font-size: $p7-cell_size_port * 0.7;
                }
                @media (orientation: landscape) {
                  width: $p7-cell-size_land;
                  height: $p7-cell-size_land;
                  font-size: $p7-cell_size_land * 0.7;
                }

                color: $p7-color_green;
                background: $p7-color_yellow;
                border: gray 1px solid;
                border-radius: 0.4vh;
                margin: 0.08vh;

                &.active {
                  background: $p7-color_green;
                  color: $p7-color_yellow;
                }

                &.micro-1 {
                  z-index: 20;
                }

                &.micro-2 {
                  z-index: 19;
                  top: 0.3vh;
                  left: 0.3vh;
                }

                &.micro-3 {
                  z-index: 18;
                  top: 0.6vh;
                  left: 0.6vh;
                }

                &.micro-4 {
                  z-index: 17;
                  top: 0.9vh;
                  left: 0.9vh;
                }

                &.micro-5 {
                  z-index: 16;
                  top: 1.2vh;
                  left: 1.2vh;
                }
              }
            }
          }

          .cell {
            position: relative;

            @media (orientation: portrait) {
              width: $p7-cell-size_port;
              height: $p7-cell-size_port;
              font-size: $p7-cell_size_port * 0.7;
            }
            @media (orientation: landscape) {
              width: $p7-cell-size_land;
              height: $p7-cell-size_land;
              font-size: $p7-cell_size_land * 0.7;
            }

            background: white;
            border: gray 1px solid;
            border-radius: 0.4vh;
            margin: 0.08vh;

            color: white;
            font-weight: bolder;
            text-align: center;

            text-shadow: 1px 1px 2px #000;

            display: flex;
            align-items: center;
            justify-content: center;

            &.inc {
              background: $p7-color_red;
            }

            &.equal {
              background: $p7-color_blue;
            }

            &.active {
              font-size: 0;

              background: $p7-color_yellow;

              img {
                height: 70%;
              }
            }

            &.future {
              font-size: 0;
              background: white;

              img {
                height: 70%;
              }
            }

            &.head {
              font-size: 0;
              background: white;

              img {
                height: 90%;
              }
            }
          }
        }

        .action-bottom {
        }

        .group {
          display: flex !important;

          @media (orientation: portrait) {
            flex-direction: column !important;
          }
          @media (orientation: landscape) {
            flex-direction: row !important;
            justify-content: space-around !important;
          }
        }
      }

      .button {
        pointer-events: all;
        cursor: pointer;

        font-weight: bold;

        @media (orientation: portrait) {
          width: $p7_button_one-width_port;
          font-size: $p7_button_one-height_port * 0.5;
        }

        @media (orientation: landscape) {
          width: $p7_button_one-width_land;
          font-size: $p7_button_one-height_land * 0.6;
        }

        &.yellow {
          background: linear-gradient(to bottom, $p7-color_yellow 0%, darken($p7-color_yellow, 5%) 100%);
        }

        &.blue {
          background: linear-gradient(to bottom, $p7-color_blue 0%, darken($p7-color_blue, 5%) 100%);
        }

        background: linear-gradient(to bottom, $p7-color_button 0%, darken($p7-color_button, 5%) 100%);
        color: white;

        text-shadow: 1px 1px 2px #000;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

        font-style: italic;
        text-transform: uppercase;
        text-align: center;

        border: transparent 0 solid;
        border-radius: 1.5vh;
        padding: 0.25rem 0.15rem;
      }

      .info-bottom {
        pointer-events: all;
        text-align: center;
        color: white;

        margin-top: 1.0vh;
        margin-bottom: 1.0vh;

        .tt {
          --fa-primary-color: red;
          --fa-secondary-color: white;
          --fa-secondary-opacity: 1.0;
        }
      }

      .bottom-zone {
        pointer-events: all;
        border: transparent 0 solid;
        border-radius: 1.0vh;
        font-weight: bold;

        &.kick {
          background: $p7-color_yellow;
          color: $p7-color_blue;
          text-shadow: 1px 1px 2px #808080;

          &.active {
            color: $p7-color_red;
          }
        }

        &.defense {
          background: $p7-color_blue;
          color: $p7-color_yellow;
          text-shadow: 1px 1px 2px #808080;

          &.active {
            color: $p7-color_red;
          }
        }

        @media (orientation: portrait) {
          font-size: $p7-cell_size_port * 0.7;
          padding: 0.2vh 1.8vh;
          margin: 1.8vh 0.8vh;
        }

        @media (orientation: landscape) {
          font-size: $p7-cell_size_port * 0.7;
          padding: 0.2vh 1.8vh;
          margin: 1.8vh 0.8vh;
        }
      }
    }

    .upshift {

      @media (orientation: portrait) {
        margin-bottom: 6vh;
      }
      @media (orientation: landscape) {
        margin-bottom: 0;
      }
    }

    .a-layer {
      position: absolute;
      z-index: 80;
      pointer-events: none;

      //      border: violet solid 2px;

      svg {
        //        border: 1px solid red;
      }

      path {
        fill: none;
        stroke-dasharray: 5 3;
        stroke: white;
        stroke-width: 0.4vh;
        vector-effect: non-scaling-stroke;
      }
    }

    .copyright {
      height: $p7-footer-height;
      background: white;
      text-align: center;
      font-size: 0.8rem;
    }
  }

  .lang-selector {
    right: 0.8vh;
    top: 0.8vh;

    @media (orientation: portrait) {
      font-size: $p7-font-info_s3-port;
    }
    @media (orientation: landscape) {
      font-size: $p7-font-info_s3-land;
    }

  }

  @keyframes anires {
    0%, 100% {
      background: $p7-color_red;
    }
    50% {
      background: $p7-color_blue;
    }
  }

  @include make_border_part(border, top, white-1, 1px, white, solid);
  @include make_border_part(border, top, white-2, 2px, white, solid);
  @include make_border_part(border, bottom, white-2, 2px, white, solid);
  @include make_border_part(border, bottom, white-1, 1px, white, solid);

  @include make_border_part(border, top, grey-1, 1px, grey, solid);
  @include make_border_part(border, bottom, grey-1, 1px, grey, solid);
  @include make_border_part(border, left, grey-1, 1px, grey, solid);
  @include make_border_part(border, right, grey-1, 1px, grey, solid);

  @include make_border_part(border, top, blue-1, 1px, $p7-color_blue, solid);
  @include make_border_part(border, top, blue-2, 2px, $p7-color_blue, solid);
  @include make_border_part(border, bottom, blue-2, 2px, $p7-color_blue, solid);
  @include make_border_part(border, bottom, blue-1, 1px, $p7-color_blue, solid);

  .check {

    .text2 {
      font-size: 0.8rem;
    }

    .lang-selector {
      z-index: 10;
    }

    .ht_ {
      height: $p7-subtop-height + $p7-top-height;
      position: fixed;
      z-index: 2;
      left: 0;
      top: 0;
    }

    .bt_ {
      margin-top: $p7-subtop-height + $p7-top-height;
    }

    .subtop {
      position: relative;
      background: $p7-color_green2;
      color: blue;
    }

    .top {
      position: relative;
      background: linear-gradient(to bottom, $p7-color_sky 0%, darken($p7-color_sky, 7%) 100%);
      color: white;

      .active {
        color: white;
        text-decoration: underline;
      }

      .title {
        font-size: 0.9rem;
        text-align: center;
      }

      .menu {
        position: absolute;
        left: 0;
        top: 0;
      }

      .search {
        position: absolute;
        right: 0;
        top: 0;
      }

      .rules {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .logo {
        //        background: #5CB03C;
        position: relative;
        height: 1.1rem;
        width: 1.1rem;

        .pb-logo {
          vertical-align: baseline;
          height: 100%;
          width: 100%;
        }

        .date, .time {
          position: absolute;
          white-space: nowrap;
          top: 50%;
        }

        .date {
          left: -0.25rem;
          transform: translate(-100%, -50%);
          text-align: right;
        }

        .time {
          right: -0.25rem;
          transform: translate(100%, -50%);
          text-align: left;
        }
      }
    }

    .header1 {
      background: $p7-color_green;
      color: white;
    }

    .header2 {
      background: $p7-color_green;
      color: white;

      .number {
        background: $p7-color_green;
        color: white;
      }

      .score {
        background: $p7-color_green;
      }
    }

    .line {
      //      height: $p7-line_heigth;
    }

    .score-info {
      .left, .right {
        position: absolute;
        white-space: nowrap;
        top: 50%;
      }

      .left {
        left: 0;
        transform: translate(-100%, -50%);
      }

      .right {
        right: 0;
        transform: translate(100%, -50%);
      }

      .icon1 {
        height: $p7-line_heigth * 0.75;
      }

      .icon2 {
        height: $p7-line_heigth * 0.60;
      }

      img {
        position: relative;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: baseline;
      }
    }

    .score {
      width: $p7-score_width;
      background: beige;
      color: white;
      text-shadow: 1px 1px 2px #000;
      font-size: 1.1rem;

      &.hit {
        background: $p7-color_red;
      }

      &.miss {
        background: $p7-color_blue;
      }

      .icon2 {
        height: $p7-line_heigth * 0.8;
      }

      .icon {
        height: $p7-line_heigth;
      }

      img {
        position: relative;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: unset;
      }
    }

    .number {
      width: $p7-number_width;
      background: $p7-color_yellow;
      color: $p7-color_blue;
      border-right: 1px solid grey;
      border-left: 1px solid grey;

      &.future {
        background: beige;
      }
    }

    .result {
      width: (100% - 2*$p7-score_width - $p7-number_width)/2;

      .info {
        font-size: 0.7rem;
        background: white;
      }

      &.select {
        .mini {
          background: $p7-color_yellow;

          &.hit, &.hit-hand, &.miss {
            background: $p7-color_yellow;
          }
        }
      }

      .gate {
        border-left: 5px solid white;
        border-top: 5px solid white;
        border-right: 5px solid white;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background: $p7-color_green;
        height: $p7-line_heigth;

        color: white;
        text-shadow: 1px 1px 2px #808080;

        .nickname {
          cursor: pointer;
          font-size: 0.8rem;
        }
      }

      .mini {
        font-size: 0.8rem;
        font-weight: bold;
        color: $p7-color_blue;
        text-shadow: 1px 1px 2px #808080;
        background: beige;
        border-left: solid 1px grey;

        &:first-child {
          border-left: solid 1px transparent;
        }

        &.hit {
          background: lighten($p7-color_red, 30%);
        }

        &.hit-hand {
          background: beige;
        }

        &.miss {
          background: lighten($p7-color_blue, 30%);
        }

        .icon {
          height: $p7-line_heigth;
        }

        img {
          position: relative;
          height: 90%;
          top: 60%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &-1 {
          width: 100%;
        }

        &-2 {
          width: (100% / 2);
        }

        &-3 {
          width: (100% / 3);
        }

        &-4 {
          width: (100% / 4);
        }

        &-5 {
          width: (100% / 5);
        }
      }

    }
  }

  .training-result {
    border: $p7-color_blue solid 2px;
    text-align: center;

    .trc {
      border: $p7-color_blue solid 1px;
      width: percentage(9/100);
    }
  }

  .shake {
    animation: shake 5s infinite cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    transform-origin: 50% 100%;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(0, 0, 0) rotate(3deg);
    }

    20%, 80% {
      transform: translate3d(0, 0, 0) rotate(-5deg);
    }

    30%, 50%, 70% {
      transform: translate3d(0, 0, 0) rotate(7deg);
    }

    40%, 60% {
      transform: translate3d(0, 0, 0) rotate(-7deg);
    }
  }
}

.tooltip {
  z-index: 300;
}

.tooltip-inner {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important;
  background: white;
  color: $p7-color_blue;
  border-radius: 6px;
}

.arrow::before {
  border-bottom-color: white !important;
  border-top-color: white !important;
}

.arrow::after {
  border-bottom-color: white !important;
  border-top-color: white !important;
}

.sc-body {
  border: solid 2px $p7-color_blue;
}

.info-white {
  background: white;
  color: blue;
}
