/* autoprefixer grid: autoplace */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

// Import Bootstrap source files
@import '~bootstrap/scss/bootstrap';
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";

@import "~@sig/probapps-lib/scss/common";

@import "penalty-vars";
@import "penalty";
