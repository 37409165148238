// ************************************************** SIZE
// ---------------------------------------------- LANDSCAPE
$p7-gate-aspect_land: 600 / 208;

$p7-logo-size_land: 3.4vh !default;

$p7-header-sky-height_land: 22vh !default;
$p7-tribune-height_land: 25.2vh !default;
$p7-gate-height_land: 22.5vh !default;

$p7-gate-zones-top_land: 2.2vh !default;
$p7-gate-zones-height_land: 19.4vh !default;
$p7-gate-zones-forward_land: -1.5vh !default;

$p7-info_main-height_land: 11vh !default;
$p7-info_main-width_land: 70% !default;
$p7-info_main-top_land: -0.3vh !default;

$p7-info_main-panel_width_land: 25vh !default;
$p7-info_main-panel_margin_short_land: 2.5vh !default;
$p7-info_main-panel_margin_wide_land: 10vh !default;
$p7-info_main-score_top_land: -1.3vh !default;
$p7-info_main-score_padding_land: 2.3vh !default;
$p7-info_score_margin_bottom_land: 0.0vh !default;

$p7-info_gate-top_land: 5.0vh !default;

$p7_button_one-width_land: 32vh !default;
$p7_button_one-height_land: 4vh !default;

$p7_gate-tube-horizontal-size_land: 1.4vh !default;
$p7_gate-tube-vertical-size_land: 0.9vh !default;

$p7-cell-size_land: 7vh !default;
$p7-control-score-bottom_land: 2.5vh !default;
// ------------------------------------------------- PORTRAIT
$p7-gate-aspect_port: 604 / 208;

$p7-logo-size_port: 3.2vh !default;

$p7-header-sky-height_port: 17vh !default;
$p7-header-sky-heightx_port: 15.5vh !default;
$p7-tribune-height_port: 14vh !default;
$p7-gate-height_port: 12.5vh !default;

$p7-gate-zones-top_port: 1.4vh !default;
$p7-gate-zones-height_port: 10.8vh !default;
$p7-gate-zones-heightx_port: 11.0vh !default;
$p7-gate-zones-forward_port: -0.9vh !default;

$p7-info_main-height_port: 8vh !default;
$p7-info_main-width_port: 90% !default;
$p7-info_main-top_port: -1.3vh !default;

$p7-info_main-panel_width_port: 14vh !default;
$p7-info_main-panel_margin_short_port: 1.7vh !default;
$p7-info_main-panel_margin_wide_port: 6.2vh !default;
$p7-info_main-score_top_port: -0.6vh !default;
$p7-info_main-score_padding_port: 1.4vh !default;
$p7-info_score_margin_bottom_port: 0.4vh !default;

$p7-info_gate-top_port: 1.1vh !default;

$p7_button_one-width_port: 70% !default;
$p7_button_one-height_port: 5vh !default;

$p7_gate-tube-horizontal-size_port: 0.8vh !default;
$p7_gate-tube-vertical-size_port: 0.4vh !default;

$p7-cell-size_port: 5vh !default;
$p7-control-score-bottom_port: 18.4vh !default;
// --------------------------------------------- LANDSCAPE/PORTRAIT
$p7-footer-height: 1.2rem !default;

// ************************************************** FONT
// --------------------------------------------- LANDSCAPE
$p7-font-info-land: 1vh !default;

$p7-font-info_s0-land: 6.0vh !default;
$p7-font-info_s1-land: 4.0vh !default;
$p7-font-info_s2-land: 3.0vh !default;
$p7-font-info_s3-land: 2.4vh !default;

// --------------------------------------------- PORTRAIT
$p7-font-info-port: 1vh !default;

$p7-font-info_s0-port: 4.0vh !default;
$p7-font-info_s1-port: 3.0vh !default;
$p7-font-info_s2-port: 2.0vh !default;
$p7-font-info_s2x-port: 1.5vh !default;
$p7-font-info_s3-port: 1.6vh !default;

// ************************************************** COLOR
$p7-color_blue: rgb(0, 86, 161) !default;
$p7-color_red: rgb(253, 15, 14) !default;
$p7-color_green: #5CB03C !default;
$p7-color-yellow: #FFDF00 !default;

$p7-color_sky: rgb(0, 87, 158) !default;
$p7-color_info_main: rgb(0, 0, 0) !default;
$p7-color_button: rgb(224, 34, 31) !default;


// ************************************************ CHECK
$p7-score_width: 10%;
$p7-number_width: 8%;
$p7-line_heigth: 1.7rem;

$p7-color_green: rgb(60, 162, 78) !default;
$p7-color_green2: rgb(196, 225, 220) !default;
$p7-color_green3: rgb(0, 140, 1) !default;


$p7-top-height: 5rem;
$p7-subtop-height: 7rem;
